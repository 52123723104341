import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="py-4 px-8 text-center bg-secondary text-text">
      <p className="">
        Made by <a href="https://lenny.ie" target="_blank"
                   rel="noopener noreferrer"
                   className="hover:bg-accent hover:text-white transition-colors duration-300 px-1 rounded">lenny.ie</a> (contact
        for dev-work) &hearts;
      </p>
      <p className="">&copy; {currentYear} Wildism Coins. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
