import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';

const FAQ = () => {
  return (
    <div className="flex flex-col min-h-screen bg-background text-text">
      <Header />
      <main className="container mx-auto px-8 py-12 flex-grow">
        <section className="text-center">
          <h2 className="text-5xl font-bold mb-8">FAQ</h2>
          <div className="text-left">
            {faqItems.map((item, index) => (
              <details key={index} className="mb-4">
                <summary className="cursor-pointer bg-primary p-4 rounded-lg">
                  {item.question}
                </summary>
                <div className="bg-secondary p-4 rounded-lg mt-2">
                  {item.answer}
                </div>
              </details>
            ))}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

const faqItems = [
  {
    question: "What is the method you use for transferring coins?",
    answer: "Our method will be revealed upon payment and it is expected that you keep it confidential to ensure your own safety."
  },
  {
    question: "What payment methods do you accept?",
    answer: "We accept Paypal, Cashapp, Crypto, Apple Pay, debit card, and visa gift cards for coin purchases and account sales."
  },
  {
    question: "How is the middleman fee paid?",
    answer: "Preferably through Crypto, Cashapp, Venmo, or Paypal, but other payment methods may also be accepted."
  },
  {
    question: "How do I buy and sell crypto?",
    answer: "First you must understand the difference between an exchange and a wallet. A wallet is for holding and an exchange is similar to a bank account, allowing you to purchase (and in most cases hold) crypto at will. Exodus is a recommended wallet and exchanges like Coinbase (which requires you to have a ID) are recommended for purchasing. If you are under 18 we offer exchanging services with a flat 10% fee through support tickets."
  }
];

export default FAQ;
