import React, { useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { FaDiscord } from 'react-icons/fa';

const ScammerList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [scammerData, setScammerData] = useState(null);
  const [reportStatus, setReportStatus] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();

    if (searchQuery.trim().length < 3) {
      setScammerData(null);
      setReportStatus('Please enter at least 3 characters.');
      return;
    }

    try {
      setIsSearching(true);

      const response = await fetch(`https://api.wildismcoins.net/scammer/lookup/${encodeURIComponent(searchQuery)}`, {
        credentials: 'include',
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }

      if (data.status === 'OK') {
        setScammerData(data.response);
        setReportStatus('');
      } else {
        setScammerData(null);
        setReportStatus('No scammers found for the given query.');
      }
    } catch (error) {
      console.error('Error fetching scammer data:', error);
      setScammerData(null);
      setReportStatus(`Error: ${error.message}`);
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-background text-text">
      <Header />

      <main className="container mx-auto px-8 py-12 flex-grow">
        <section className="text-center">
          <h2 className="text-5xl font-bold mb-8">Scammer List</h2>
          <p className="text-3xl mb-10">View and report scammers in the Minecraft trading community</p>

          <div className="mt-12">
            <h3 className="text-4xl font-bold mb-4">Search Scammer List</h3>
            <form onSubmit={handleSearch} className="flex justify-center items-center">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Enter your search query"
                className="px-4 py-3 rounded-lg border-2 border-gray-500 focus:outline-none focus:border-accent text-black w-64 shadow-md"
              />
              <button
                type="submit"
                className="bg-accent hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg ml-4"
                disabled={isSearching}
              >
                {isSearching ? 'Searching...' : 'Search'}
              </button>
            </form>
          </div>

          <div>
            <h3 className="text-4xl font-bold mt-12 mb-4">Report Scammer</h3>
            <p className="text-lg mb-4">
              To report a scammer, please join our official scammer list Discord server:
            </p>
            <div className="flex flex-col items-center mt-8">
              <a
                href="https://discord.gg/scammer-list-1021087112830914622"
                className="bg-secondary hover:bg-secondary-dark text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg"
              >
                <FaDiscord className="mr-2"/>
                Join the Official Scammer List Discord
              </a>
            </div>
          </div>

          {reportStatus && (
            <div className="mt-4">
              <p
                className={`text-xl ${
                  reportStatus.includes('Error') ? 'text-red-500 text-center' : 'text-green-500 text-center'
                }`}
              >
                {reportStatus}
              </p>
            </div>
          )}

          {scammerData && Array.isArray(scammerData) ? (
            <div>
              <h3 className="text-4xl font-bold mb-4 mt-12">Search Results</h3>
              {scammerData.map((scammer, index) => (
                <div key={index} className="bg-primary rounded-lg p-6 mb-4">
                  <h4 className="text-2xl font-bold mb-2">{scammer.tag}</h4>
                  <p className="text-xl mb-2">Discord ID: {scammer.id}</p>
                  <p className="text-xl mb-2">Method: {scammer.method}</p>
                  <p className="text-xl mb-2">Amount: {scammer.amount}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className="mt-4">
              {reportStatus && (
                <p
                  className={`text-xl ${
                    reportStatus.includes('Error') ? 'text-red-500 text-center' : 'text-green-500 text-center'
                  }`}
                >
                  {reportStatus}
                </p>
              )}
            </div>
          )}
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default ScammerList;