import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';

const SellAccountPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="container mx-auto px-8 py-12 flex-grow">
        <section className="text-center">
          <h2 className="text-5xl font-bold mb-8">Sell Account Policy</h2>
        </section>
        <section className="bg-primary rounded-lg p-6 text-left">
          <div className="space-y-6 text-text">
            <div>
              <h3 className="text-3xl font-bold mb-4">1. Account Eligibility</h3>
              <p>We do not purchase accounts obtained through phishing, or those with manipulated net worth such as through duping or ratting. Additionally, Ironman and Stranded profiles are not eligible for sale due to limited demand. However, banned profiles may be considered for purchase upon expiration of the ban, though their value may be affected by the account's ban history.</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">2. Middleman Service</h3>
              <p>For added security, we offer the option to utilize a Middleman service, though any associated fees will be the responsibility of the seller. We can also provide evidence of past transactions and vouches upon request. However, please note that only a select few Middlemen (Zyger, Wildism, Lenny) are endorsed by our service.</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">3. Payment Processing</h3>
              <p>Payment will be processed within 24 hours, as per the industry standard safeguarding measure. This is to ensure that the Minecraft Session ID has expired. In some rare cases, payment may be delayed by up to an additional 7 days if we detect suspicious account activity (e.g. account sharing, IRL trading, token logging, etc.)</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">4. Account Transfer</h3>
              <p>Upon sale, sellers will forfeit access to their Microsoft account, although access to emails will be retained. Additionally, the account will be anonymized upon transfer to remove any personal information.</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">5. Accuracy of Information</h3>
              <p>Sellers are responsible for providing accurate information. Please ensure that all information provided to us is fully accurate as we are not liable for any losses incurred due to inaccurate information.</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">6. Finality of Transaction</h3>
              <p>Once the account transfer process is completed and the account has been secured by our account sellers, the transaction is considered final. To ensure safety of accounts and consideration of the time spent by our account sellers, we reserve the right to reject any cancellation requests, or to charge a cancellation fee after the account has been secured.</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">7. Fraudulent Activities</h3>
              <p>All fraudulent activities are strictly prohibited while using our services. If we receive evidence that funds or accounts distributed to us were obtained through fraudulent means, we reserve the right to deny your use of our services, confiscate any funds or accounts obtained through fraudulent means, return any fraudulently obtained funds or items to their rightful owner, provide evidence of your fraudulent activities to scammer lists, and/or take any other action we deem necessary. If you feel that you have been falsely accused of fraudulent activities, you may request a review of your case from a trusted independent third party (i.e., Scammer List).</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">Further Inquiries</h3>
              <p>Should you have any inquiries or require clarification regarding our policies, please don't hesitate to contact us via our Discord Server.</p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default SellAccountPolicy;
