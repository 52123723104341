import React, {useCallback, useEffect} from 'react';

const Modal = ({isOpen, onClose, children}) => {
  const handleOutsideClick = useCallback((event) => {
    if (event.target.classList.contains('modal-overlay')) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, handleOutsideClick]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 modal-overlay">
      <div className="bg-background rounded-lg p-6 w-full max-w-md mx-auto">
        {children}
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-full"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
