import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';

const MiddlemanPage = () => {
  const [deal, setDeal] = useState('');
  const [totalWorth, setTotalWorth] = useState(10);
  const [otherUserID, setOtherUserID] = useState('');
  const [reportStatus, setReportStatus] = useState('');
  const [deals, setDeals] = useState([]);
  const [sortOption, setSortOption] = useState('mostRecent');

  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await fetch('https://api.wildismcoins.net/deals');
        const data = await response.json();
        if (data.status === 'OK') {
          setDeals(data.deals);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching deals:', error);
      }
    };

    fetchDeals();
  }, []);

  useEffect(() => {
    sortDeals();
  }, [sortOption]);

  const sortDeals = () => {
    let sortedDeals = [...deals];
    if (sortOption === 'highestToLowest') {
      sortedDeals.sort((a, b) => b.value - a.value);
    } else if (sortOption === 'lowestToHighest') {
      sortedDeals.sort((a, b) => a.value - b.value);
    } else if (sortOption === 'mostRecent') {
      sortedDeals.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }
    sortedDeals = sortedDeals.filter(deal => formatDate(deal.createdAt) !== 'Unknown').concat(
      sortedDeals.filter(deal => formatDate(deal.createdAt) === 'Unknown')
    );
    setDeals(sortedDeals);
  };

  const calculateMiddlemanFee = () => {
    const effectiveWorth = totalWorth < 10 ? 10 : totalWorth;
    if (effectiveWorth < 250) {
      return 10;
    } else {
      const feePercentage = 0.04;
      return effectiveWorth * feePercentage;
    }
  };

  const handleOtherUserIDChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setOtherUserID(value);
  };

  const handleTotalWorthChange = (e) => {
    const value = parseFloat(e.target.value);
    setTotalWorth(value);
  };

  const handleSliderChange = (e) => {
    const value = parseInt(e.target.value);
    setTotalWorth(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!deal.trim() || !otherUserID.trim()) {
      setReportStatus('Please fill in all required fields.');
      return;
    }

    try {
      const payload = {
        type: 'middleman',
        deal: deal,
        value: totalWorth.toString(),
        otheruserid: otherUserID,
      };

      const response = await fetch('https://api.wildismcoins.net/ticket', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log('Response:', responseData);
        setReportStatus(responseData.message);
      } else {
        console.error('Error:', responseData.message);
        setReportStatus(`Error (${response.status}): ${responseData.message}`);
      }
    } catch (error) {
      console.error('Error submitting report:', error);
      setReportStatus('Error submitting report: Invalid server response');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const isToday = date.toDateString() === today.toDateString();
    return isNaN(date.getTime()) || isToday ? 'Unknown' : date.toLocaleDateString();
  };

  const hasUserCookie = document.cookie.includes('user=');

  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <main className="container mx-auto px-8 py-12 flex-grow">
        <section className="text-center">
          <h2 className="text-5xl font-bold mb-8">Middleman Request</h2>
          <p className="text-xl mb-10">Use our middleman service to ensure swift and secure transactions</p>
        </section>

        <section className="grid grid-cols-1 gap-8">
          <div className="bg-primary rounded-lg p-6">
            <h3 className="text-3xl font-bold mb-4">Calculate Fee</h3>
            <input
              type="range"
              min={10}
              max={10000}
              step={10}
              value={totalWorth}
              onChange={handleSliderChange}
              className="w-full"
            />
            <div className="flex justify-between text-lg mt-4">
              <span>$10</span>
              <span>$10,000</span>
            </div>
            <input
              type="number"
              value={totalWorth}
              onChange={handleTotalWorthChange}
              className="w-full bg-secondary py-3 px-4 rounded-lg text-lg text-center mt-4"
              min="0"
              step="1"
            />
            <div className="mt-4">
              <p className="text-xl">Selected Amount: ${totalWorth.toLocaleString()}</p>
              <p className="text-xl">Middleman Fee: ${calculateMiddlemanFee().toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
            </div>
          </div>

          {hasUserCookie && (
            <>
              <div className="bg-primary rounded-lg p-6">
                <h3 className="text-3xl font-bold mb-4">Deal</h3>
                <input
                  type="text"
                  placeholder="Deal description"
                  value={deal}
                  onChange={(e) => setDeal(e.target.value)}
                  className="w-full bg-secondary text-white py-3 px-4 rounded-lg text-lg"
                  required
                />
              </div>

              <div className="bg-primary rounded-lg p-6">
                <h3 className="text-3xl font-bold mb-4">Other User ID</h3>
                <input
                  type="text"
                  placeholder="Other user ID"
                  value={otherUserID}
                  onChange={handleOtherUserIDChange}
                  className="w-full bg-secondary py-3 px-4 rounded-lg text-lg"
                  required
                />
              </div>

              <div className="flex justify-center">
                <button
                  className="bg-accent hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
              <div className="mt-4">
                {reportStatus && (
                  <p
                    className={`text-xl ${reportStatus.includes('Error') ? 'text-red-500 text-center' : 'text-green-500 text-center'}`}>
                    {reportStatus}
                  </p>
                )}
              </div>
            </>
          )}

          {!hasUserCookie && (
            <div className="mt-12 flex justify-center">
              <div className="flex flex-col items-center">
                <p className="text-lg mb-4">
                  By logging in with Discord, we can seamlessly pull you into our official server and automatically
                  generate your ticket for a smoother experience.
                </p>
                <a
                  href="https://discord.com/oauth2/authorize?client_id=1281947269284761660&redirect_uri=https%3A%2F%2Fapi.wildismcoins.net%2Fcallback&response_type=code&scope=identify%20guilds.join"
                  className="bg-blue-500 hover:bg-blue-600 text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg"
                >
                  Login with Discord!
                </a>
              </div>
            </div>
          )}
        </section>

        <section className="mt-10">
          <div className="flex justify-center items-center mb-6">
            <h2 className="text-4xl font-bold text-center mr-4">Deals I've Middlemanned</h2>
            <select
              className="bg-secondary text-text py-2 px-4 rounded"
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <option value="mostRecent">Most Recent</option>
              <option value="highestToLowest">Highest to Lowest</option>
              <option value="lowestToHighest">Lowest to Highest</option>
            </select>
          </div>
          <div className="flex flex-wrap justify-center gap-6">
            {deals.map((deal, index) => (
              <div key={index} className="bg-primary

 rounded-lg p-6 shadow-lg w-full md:w-1/3 lg:w-1/4">
                <div className="mb-4">
                  <span className="text-lg font-bold">Deal ID:</span> {deal.id}
                </div>
                <div className="mb-4">
                  <span className="text-lg font-bold">Seller:</span> {deal.seller}
                </div>
                <div className="mb-4">
                  <span className="text-lg font-bold">Buyer:</span> {deal.buyer}
                </div>
                <div className="mb-4">
                  <span className="text-lg font-bold">Value:</span> ${deal.value}
                </div>
                <div className="mb-4">
                  <span className="text-lg font-bold">Info:</span> {deal.info}
                </div>
                <div>
                  <span className="text-lg font-bold">Date:</span> {formatDate(deal.createdAt)}
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default MiddlemanPage;