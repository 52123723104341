import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

const SuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const referrer = document.referrer;
    const isSameDomain = referrer && new URL(referrer).origin === window.location.origin;

    const redirectTimer = setTimeout(() => {
      if (isSameDomain) {
        navigate(-1);
      } else {
        navigate('/');
      }
    }, 1500);

    return () => {
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <main className="container mx-auto px-8 py-12 flex-grow">
        <section className="text-center">
          <h2 className="text-5xl font-bold mb-8">Thank You!</h2>
          <p className="text-xl mb-10">Thank you for logging in with Discord. You will be redirected to the previous page in 1.5 seconds.</p>
        </section>

      </main>

      <Footer />
    </div>
  );
};

export default SuccessPage;
