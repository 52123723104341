import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Modal from './components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faBitcoin, faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard, faMoneyBillWave, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import {FaDiscord} from "react-icons/fa";

const AccountDetailPage = () => {
  const { accountNumber } = useParams();
  const [account, setAccount] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [reportStatus, setReportStatus] = useState('');
  const [isReporting, setIsReporting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const response = await fetch(`https://api.wildismcoins.net/account/${accountNumber}`);
        const data = await response.json();
        if (response.status === 200) {
          setAccount(data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching account:', error);
      }
    };

    fetchAccount();
  }, [accountNumber]);

  const getPaymentIcon = (method) => {
    switch (method.toLowerCase()) {
      case 'paypal':
        return faPaypal;
      case 'crypto':
        return faBitcoin;
      case 'credit card':
        return faCreditCard;
      case 'bank transfer':
        return faUniversity;
      case 'cash app':
        return faMoneyBillWave;
      case 'apple cash':
        return faApple;
      default:
        return faCreditCard;
    }
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleBuyClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPaymentMethod('');
    setReportStatus('');
  };

  const handleSubmit = () => {
    if (!paymentMethod) {
      setReportStatus('Please enter a payment method');
      return;
    }

    setReportStatus('');

    const payload = {
      type: 'account-buy',
      accountNumber: account.accountNumber,
      paymentMethod: paymentMethod,
    };

    setIsReporting(true);
    fetch('https://api.wildismcoins.net/ticket', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        setIsReporting(false);
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status === 'OK') {
          console.log('Response:', responseData);
          setReportStatus(responseData.message);
        } else {
          console.error('Error:', responseData.message);
          setReportStatus(`Error: ${responseData.message}`);
        }
      })
      .catch((error) => {
        setIsReporting(false);
        console.error('Error creating ticket:', error);
        setReportStatus('Error creating ticket');
      });
  };

  const formatRank = (rank) => {
    switch (rank) {
      case '[NON]':
        return (
          <span style={{ color: '#AAAAAA' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            NON
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      case '[VIP]':
        return (
          <span style={{ color: '#55FF55' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            VIP
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      case '[VIP+]':
        return (
          <span style={{ color: '#55FF55' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            VIP
            <span style={{ color: '#FFFFFF' }}>+</span>
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      case '[MVP]':
        return (
          <span style={{ color: '#55FFFF' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            MVP
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      case '[MVP+]':
        return (
          <span style={{ color: '#55FFFF' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            MVP
            <span style={{ color: '#FFFFFF' }}>+</span>
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      case '[MVP++]':
        return (
          <span style={{ color: '#FFAA00' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            MVP
            <span style={{ color: '#FFFFFF' }}>+</span>
            <span style={{ color: '#FFFFFF' }}>+</span>
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      case '[YOUTUBE]':
        return (
          <span style={{ color: '#FF5555' }}>
            <span style={{ color: '#FFFFFF' }}>[</span>
            YOUTUBE
            <span style={{ color: '#FFFFFF' }}>]</span>
          </span>
        );
      default:
        return <span style={{ color: '#AAAAAA' }}>{rank}</span>;
    }
  };

  if (!account) return <div>Loading...</div>;

  const hasUserCookie = document.cookie.includes('user=');

  return (
    <div className="flex flex-col min-h-screen bg-background text-text">
      <Helmet>
        <title>Account {accountNumber} - Hypixel Skyblock Account</title>
        <meta
          property="og:title"
          content={`Skyblock Account ${accountNumber}`}
        />
        <meta
          property="og:description"
          content={`
            Rank: ${account.stats.rank}
            Level: ${account.stats.sblevel.toFixed(2)}
            Skill Average: ${account.stats.skillaverage.toFixed(2)}
            Slayer: ${account.stats.slayers.zombie}/${account.stats.slayers.spider}/${account.stats.slayers.wolf}/${account.stats.slayers.enderman}
            Networth: ${account.stats.networth.total}
            Price: $${account.price}
          `}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />

      <main className="container mx-auto px-8 py-12 flex-grow">
        <section className="text-center">
          <h2 className="text-5xl font-bold mb-8">Account Details</h2>
        </section>

        <section className="mt-10">
          <div className="bg-primary rounded-lg p-6 shadow-lg flex flex-col justify-between h-full">
            <div className="mb-4">
              <span className="font-bold">Rank:</span> {formatRank(account.stats.rank)}
            </div>
            <div className="mb-4 grid grid-cols-3 gap-4">
              <div>
                <span className="font-bold">Level:</span>
                <div>{account.stats.sblevel.toFixed(2)}</div>
              </div>
              <div>
                <span className="font-bold">Skill Average:</span>
                <div>{account.stats.skillaverage.toFixed(2)}</div>
              </div>
              <div>
                <span className="font-bold">Slayer:</span>
                <div>{`${account.stats.slayers.zombie}/${account.stats.slayers.spider}/${account.stats.slayers.wolf}/${account.stats.slayers.enderman}`}</div>
              </div>
            </div>
            <div className="mb-4 grid grid-cols-3 gap-4">
              <div>
                <span className="font-bold">Networth:</span>
                <div>Total: {account.stats.networth.total}</div>
                <div>Unsoulbound: {account.stats.networth.unsoulbound}</div>
                <div>Coins: {account.stats.networth.coins}</div>
              </div>
              <div>
                <span className="font-bold">Weight:</span>
                <div>Senither: {account.stats.weight.senither}</div>
                <div>Lily: {account.stats.weight.lily}</div>
              </div>
              <div>
                <span className="font-bold">Dungeons:</span>
                <div>Catacombs: {account.stats.dungeons.level}</div>
                <div>Class Average: {account.stats.dungeons.average.toFixed(2)}</div>
              </div>
            </div>
            <div className="mb-4 grid grid-cols-2 gap-4">
              <div>
                <span className="font-bold">Mining:</span>
                <div>HOTM Level: {account.stats.mining.hotm}</div>
                <div>Mithril Powder: {account.stats.mining.mithril}</div>


                <div>Gemstone Powder: {account.stats.mining.gemstone}</div>
              </div>
              <div>
                <span className="font-bold">Minions:</span>
                <div>Total Slots: {account.stats.minions.total_slots}</div>
                <div>Until Next Slot: {account.stats.minions.crafted_slots}</div>
                <div>Bonus Slots: {account.stats.minions.bonus_slots}</div>
              </div>
            </div>
            <div className="mb-4">
              <span className="font-bold">Extra Stats:</span>
              <div>{account.extraStats}</div>
            </div>
            <div className="mb-4">
              <span className="font-bold">Price:</span>
              <div>${account.price}</div>
            </div>
            <div className="mb-4">
              <span className="font-bold">Payment Methods:</span>
              <div className="flex flex-wrap gap-2">
                {account.paymentMethods.map((method, methodIndex) => (
                  <span key={methodIndex} className="bg-secondary text-text py-1 px-2 rounded flex items-center">
                    <FontAwesomeIcon icon={getPaymentIcon(method)} className="mr-2" />
                    {method}
                  </span>
                ))}
              </div>
            </div>
            {hasUserCookie ? (
              <div className="flex justify-center mt-6 space-x-4">
                <button
                  className="bg-accent hover:bg-accent-dark text-white py-2 px-6 rounded-full text-lg transform transition-transform duration-200 hover:scale-105"
                  onClick={handleBuyClick}
                >
                  Buy Account
                </button>
              </div>
            ) : (
              <div className="mt-12 flex justify-center">
                <div className="flex flex-col items-center">
                  <p className="text-lg mb-4">
                    By logging in with Discord, we can seamlessly pull you into our official server and automatically
                    generate your ticket for a smoother experience.
                  </p>
                  <a
                    href="https://discord.com/oauth2/authorize?client_id=1281947269284761660&redirect_uri=https%3A%2F%2Fapi.wildismcoins.net%2Fcallback&response_type=code&scope=identify%20guilds.join"
                    className="bg-accent hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg"
                  >
                    <FaDiscord className="mr-2"/>
                    Login with Discord!
                  </a>
                  <p className="text-lg mt-4">
                    Alternatively, you can join our official Discord server directly:
                  </p>
                  <a
                    href="https://discord.gg/E38R47T7"
                    className="bg-secondary hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg mt-2"
                  >
                    <FaDiscord className="mr-2"/>
                    Join Official Discord Server
                  </a>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>

      <Footer/>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <h3 className="text-3xl font-bold mb-4">Payment Method</h3>
        <input
          type="text"
          placeholder="Enter your payment method"
          value={paymentMethod}
          onChange={handlePaymentMethodChange}
          className="w-full bg-secondary py-3 px-4 rounded-lg text-lg"
        />
        <div className="flex justify-center mt-4">
          <button
            className="bg-accent hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
        {isReporting && <p className="text-blue-500 mt-4">Submitting...</p>}
        {reportStatus && (
          <p
            className={`${reportStatus.includes('Error') ? 'text-red-500 text-center mt-4' : 'text-green-500 text-center mt-4'}`}
          >
            {reportStatus}
          </p>
        )}
      </Modal>
    </div>
  );
};

export default AccountDetailPage;