import React, {useState} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import {FaDiscord} from "react-icons/fa";

const AccountBoosting = () => {
  const [ign, setIGN] = useState('');
  const [service, setService] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [reportStatus, setReportStatus] = useState('');
  const [isReporting, setIsReporting] = useState(false);

  const handleIGNChange = (event) => {
    setIGN(event.target.value);
  };

  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleSubmit = () => {
    if (!ign || !service || !paymentMethod) {
      setReportStatus('Please fill in all required fields');
      return;
    }

    setReportStatus('');

    const payload = {
      type: 'account-boost',
      ign: ign,
      service: service,
      paymentMethod: paymentMethod,
    };

    console.log('Submitting payload:', payload);

    setIsReporting(true);
    fetch('https://api.wildismcoins.net/ticket', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        setIsReporting(false);

        console.log('Response status:', response.status);
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status === 'OK') {
          console.log('Response data:', responseData);
          setReportStatus(responseData.message);
        } else {
          console.error('Error:', responseData.message);
          setReportStatus(`Error: ${responseData.message}`);
        }
      })
      .catch((error) => {
        setIsReporting(false);
        console.error('Error creating ticket:', error);
        setReportStatus('Error creating ticket');
      });

    setIGN('');
    setService('');
    setPaymentMethod('');
  };


  const hasUserCookie = document.cookie.includes('user=');

  return (
    <div className="flex flex-col min-h-screen">
      <Header/>

      <main className="container mx-auto px-8 py-12 flex-grow">
        <section className="text-center">
          <h2 className="text-5xl font-bold mb-8">Account Boosting</h2>
          <p className="text-xl mb-10">
            Account grinding services offered for every aspect of the game. Account grinding price is based on a rate of
            $4.00/hour.
          </p>
          <ul className="text-lg mb-10 list-disc list-inside">
            <li>Enderman Slayer Level 0-7 with mage setup: $56</li>
            <li>Catacombs 0 -> 24 with 200m budget: $53</li>
            <li>Rift 7/7 charms: $72</li>
            <li>Hotm 3 -> 7: $84</li>
            <li>Hotm 7 -> 10: $114</li>
          </ul>
          {!hasUserCookie && (
            <div className="mt-12 flex justify-center">
              <div className="flex flex-col items-center">
                <p className="text-lg mb-4">
                  By logging in with Discord, we can seamlessly pull you into our official server and automatically
                  generate your ticket for a smoother experience.
                </p>
                <a
                  href="https://discord.com/oauth2/authorize?client_id=1281947269284761660&redirect_uri=https%3A%2F%2Fapi.wildismcoins.net%2Fcallback&response_type=code&scope=identify%20guilds.join"
                  className="bg-accent hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg"
                >
                  <FaDiscord className="mr-2" />
                  Login with Discord!
                </a>
                <p className="text-lg mt-4">
                  Alternatively, you can join our official Discord server directly:
                </p>
                <a
                  href="https://discord.gg/E38R47T7"
                  className="bg-secondary hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg mt-2"
                >
                  <FaDiscord className="mr-2" />
                  Join Official Discord Server
                </a>
              </div>
            </div>
          )}
        </section>

        {hasUserCookie && (
          <section className="grid grid-cols-1 gap-8">
            <div className="bg-primary rounded-lg p-6">
              <h3 className="text-3xl font-bold mb-4">In-Game Name (IGN)</h3>
              <input
                type="text"
                placeholder="Enter your IGN"
                value={ign}
                onChange={handleIGNChange}
                className="w-full bg-secondary py-3 px-4 rounded-lg text-lg"
              />
            </div>

            <div className="bg-primary rounded-lg p-6">
              <h3 className="text-3xl font-bold mb-4">Service to Boost</h3>
              <input
                type="text"
                placeholder="Enter the service you want boosted"
                value={service}
                onChange={handleServiceChange}
                className="w-full bg-secondary py-3 px-4 rounded-lg text-lg"
              />
            </div>

            <div className="bg-primary rounded-lg p-6">
              <h3 className="text-3xl font-bold mb-4">Payment Method</h3>
              <input
                type="text"
                placeholder="Enter your payment method"
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
                className="w-full bg-secondary py-3 px-4 rounded-lg text-lg"
              />
            </div>

            <div className="flex justify-center">
              <button
                className="bg-accent hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg"
                onClick={handleSubmit}
              >
                Boost Account
              </button>
            </div>

            {isReporting && <p className="text-blue-500">Submitting...</p>}
            {reportStatus && (
              <p
                className={`${reportStatus.includes('Error') ? 'text-red-500 text-center' : 'text-green-500 text-center'}`}>
                {reportStatus}
              </p>
            )}
          </section>
        )}
      </main>
      <Footer/>
    </div>
  );
};

export default AccountBoosting;