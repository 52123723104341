import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';

const CoinPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="container mx-auto px-8 py-12 flex-grow">
        <section className="text-center">
          <h2 className="text-5xl font-bold mb-8">Coin Policy</h2>
        </section>
        <section className="bg-primary rounded-lg p-6 text-left">
          <div className="space-y-6 text-text">
            <div>
              <h3 className="text-3xl font-bold mb-4">1. PayPal Transactions</h3>
              <p>Due to the risk of chargebacks, all PayPal transactions must be sent via "Friends & Family". ANY PAYMENTS SENT THROUGH "Goods & Services" MAY NOT RESULT IN A REFUND. If you live in a country that does not support "Friends & Family", you will have to pay with an alternative payment method.</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">2. Legitimacy of Coins</h3>
              <p>We do our best to ensure that our coins are not obtained through any illegitimate means such as Ratting, Phishing, Scamming, etc. We have a team of Coin Sellers who carefully review all coins that are sold to the best of their ability. If any coins are suspected to be obtained through illegitimate means, we take proper action to ensure that the coins are not sold.</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">3. Trading Methods</h3>
              <p>All currently used trading methods are safe. We are constantly updating our trading methods to ensure that they are as safe as possible, however we cannot guarantee that you will not be banned and purchasing coins will always be at your own risk. We are not obligated to provide you with a refund if you get banned, however we always do our best to ensure that your transaction is as safe as possible.</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">4. Delivery Issues</h3>
              <p>If a coin seller fails to deliver your coins due to an issue on their end, you will be issued a full refund to your payment method. If a refund is unable to be issued to your payment method, you will be issued a refund via an alternative payment method or store credit. Please note that once a payment is received by us, it is considered non-refundable unless the seller fails to deliver your coins.</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">5. Accuracy of Information</h3>
              <p>Please double-check that all information provided with us is accurate. If you provide us with inaccurate information which results in a loss of coins, we are not obligated to provide you with a refund.</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">6. Refund Requests</h3>
              <p>All refund requests will be reviewed at the discretion of our Coin Sellers. If you are requesting a refund for a reason not covered in our policy, we reserve the right to deny your refund request for any reason. Some examples of reasons we may deny your refund request include, but are not limited to:</p>
              <ul className="list-disc list-inside pl-5">
                <li>You were placed into a queue and did not want to wait for your coins to be delivered.</li>
                <li>You were banned/account was locked before your coins were delivered. (You can arrange for coins to be delivered to another account)</li>
                <li>You did not provide accurate information.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">7. Refund of In-Game Items</h3>
              <p>Please note that we will NEVER be able to provide a refund of in-game items and/or coins that are sold to us due to the risks of getting banned. Please double check that you are able to receive payments through your payment method of choice before selling coins to us. For PayPal payments, you must be able to receive payments through "Friends & Family", or be able to cover the transaction fee for "Goods & Services" payments. If you are unable to receive payments through your chosen payment method, we will work with you to find an alternative payment method, however this may result in a delay in receiving your payment.</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">8. Fraudulent Activities</h3>
              <p>All fraudulent activities are strictly prohibited while using our services. If we receive evidence that funds or items distributed to us were obtained through fraudulent means, we reserve the right to deny your use of our services, confiscate any funds or items obtained through fraudulent means, return any fraudulently obtained funds or items to their rightful owner, provide evidence of your fraudulent activities to scammer lists, and/or take any other action we deem necessary. If you feel that you have been falsely accused of fraudulent activities, you may request a review of your case from a trusted independent third party (i.e., Scammer List).</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold mb-4">Further Inquiries</h3>
              <p>Should you have any inquiries or require clarification regarding our policies, please don't hesitate to contact us via our Discord Server.</p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default CoinPolicy;
