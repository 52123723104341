import React from 'react';
import {Link} from 'react-router-dom';
import {FaDiscord, FaSignOutAlt} from 'react-icons/fa';

const Header = () => {
  const getUserCookie = () => {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
      const [name, value] = cookies[i].split('=');
      if (name === 'user') {
        try {
          const decodedValue = decodeURIComponent(value);
          let jsonValue = decodedValue;
          if (jsonValue.startsWith('j:')) {
            jsonValue = jsonValue.substr(2);
          }
          const user = JSON.parse(jsonValue);
          if (user && typeof user === 'object' && user.username) {
            return user;
          }
        } catch (error) {
          console.error('Error parsing user cookie:', error);
        }
      }
    }
    return null;
  };

  const user = getUserCookie();
  const {id, username, avatar} = user || {};

  const handleLoginWithDiscord = () => {
    window.location.href = 'https://discord.com/oauth2/authorize?client_id=1281947269284761660&redirect_uri=https%3A%2F%2Fapi.wildismcoins.net%2Fcallback&response_type=code&scope=identify%20guilds.join';
  };

  const handleLogout = () => {
    document.cookie = 'user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.wildismcoins.net; secure;';
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.wildismcoins.net; secure;';
    window.location.reload();
  };

  return (
    <header className="py-4 px-6 flex justify-between items-center bg-secondary">
      <h1 className="text-4xl font-bold text-text transition-colors duration-300 hover:text-accent">
        <Link to="/">Wildism Coins</Link>
      </h1>
      <nav className="flex items-center space-x-4">
        <Link to="/faq"
              className="text-lg font-semibold text-text hover:underline transition-colors duration-300">FAQ</Link>
        <Link to="/tos"
              className="text-lg font-semibold text-text hover:underline transition-colors duration-300">TOS</Link>
        {user ? (
          <div className="flex items-center space-x-2">
            {id && avatar && (
              <img
                src={`https://cdn.discordapp.com/avatars/${id}/${avatar}.png`}
                alt={username}
                className="h-8 w-8 rounded-full border-2 border-accent"
              />
            )}
            <span className="text-lg font-semibold text-text">{username}</span>
            <button
              className="text-text hover:text-accent transition-colors duration-300"
              onClick={handleLogout}
            >
              <FaSignOutAlt className="h-6 w-6"/>
            </button>
          </div>
        ) : (
          <button
            className="text-lg font-semibold text-text flex items-center hover:underline transition-colors duration-300"
            onClick={handleLoginWithDiscord}
          >
            <FaDiscord className="h-5 w-5 mr-1"/>
            Login
          </button>
        )}
      </nav>
    </header>
  );
};

export default Header;
