import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { FaDiscord } from 'react-icons/fa';

const BuyPage = () => {
  const [coinsToBuy, setCoinsToBuy] = useState(250000000);
  const [inputValue, setInputValue] = useState('250m');
  const [ign, setIGN] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [buyPriceUnder400m, setBuyPriceUnder400m] = useState(0.075);
  const [buyPriceUnder1b, setBuyPriceUnder1b] = useState(0.07);
  const [buyPriceOver1b, setBuyPriceOver1b] = useState(0.065);
  const [reportStatus, setReportStatus] = useState('');
  const [isReporting, setIsReporting] = useState(false);

  useEffect(() => {
    fetch('https://api.wildismcoins.net/prices')
      .then((response) => response.json())
      .then((data) => {
        const buyPriceData = data.buy;
        if (buyPriceData) {
          setBuyPriceUnder400m(buyPriceData.buyPriceUnder400m || 0.075);
          setBuyPriceUnder1b(buyPriceData.buyPriceUnder1b || 0.07);
          setBuyPriceOver1b(buyPriceData.buyPriceOver1b || 0.065);
        }
      })
      .catch((error) => {
        console.error('Error fetching prices:', error);
        setReportStatus('Error fetching prices');
      });
  }, []);

  const calculatePrice = () => {
    const millions = coinsToBuy / 1000000;
    if (millions <= 400) {
      return millions * buyPriceUnder400m;
    } else if (millions <= 1000) {
      return millions * buyPriceUnder1b;
    } else {
      return millions * buyPriceOver1b;
    }
  };

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value);
    setCoinsToBuy(value);
    setInputValue(formatCoinsInput(value));
  };

  const handleCoinsInputChange = (event) => {
    const value = event.target.value.toLowerCase();
    setInputValue(value);

    let numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      setCoinsToBuy(250000000);
      return;
    }

    if (value.endsWith('b')) {
      numericValue *= 1000000000;
    } else if (value.endsWith('m')) {
      numericValue *= 1000000;
    } else {
      numericValue *= 1000000;
    }

    setCoinsToBuy(numericValue);
  };

  const formatCoinsInput = (value) => {
    if (value >= 1000000000) {
      return `${(value / 1000000000).toFixed(3)}b`;
    } else {
      return `${(value / 1000000).toFixed(0)}m`;
    }
  };

  const handleIGNChange = (event) => {
    setIGN(event.target.value);
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleSubmit = () => {
    if (!ign || !paymentMethod) {
      setReportStatus('Please fill in all required fields');
      return;
    }

    setReportStatus('');

    const payload = {
      type: 'buy',
      value: coinsToBuy.toString(),
      ign: ign,
      paymentMethod: paymentMethod,
    };

    setIsReporting(true);
    fetch('https://api.wildismcoins.net/ticket', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        setIsReporting(false);
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status === 'OK') {
          console.log('Response:', responseData);
          setReportStatus(responseData.message);
        } else {
          console.error('Error:', responseData.message);
          setReportStatus(`Error: ${responseData.message}`);
        }
      })
      .catch((error) => {
        setIsReporting(false);
        console.error('Error creating ticket:', error);
        setReportStatus('Error creating ticket');
      });

    setCoinsToBuy(250000000);
    setInputValue('250m');
    setIGN('');
    setPaymentMethod('');
  };

  const hasUserCookie = document.cookie.includes('user=');

  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <main className="container mx-auto px-8 py-12 flex-grow">
        <section className="text-center">
          <h2 className="text-5xl font-bold mb-8">Buying Coins</h2>
          <p className="text-xl mb-10">
            Find and purchase coins from our trusted sellers. Use the slider to select the number of coins you want to buy.
          </p>
          {!hasUserCookie && (
            <div className="mt-12 flex justify-center">
              <div className="flex flex-col items-center">
                <p className="text-lg mb-4">
                  By logging in with Discord, we can seamlessly pull you into our official server and automatically
                  generate your ticket for a smoother experience.
                </p>
                <a
                  href="https://discord.com/oauth2/authorize?client_id=1281947269284761660&redirect_uri=https%3A%2F%2Fapi.wildismcoins.net%2Fcallback&response_type=code&scope=identify%20guilds.join"
                  className="bg-accent hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg"
                >
                  <FaDiscord className="mr-2" />
                  Login with Discord!
                </a>
                <p className="text-lg mt-4">
                  Alternatively, you can join our official Discord server directly:
                </p>
                <a
                  href="https://discord.gg/E38R47T7"
                  className="bg-secondary hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg mt-2"
                >
                  <FaDiscord className="mr-2" />
                  Join Official Discord Server
                </a>
              </div>
            </div>
          )}
        </section>

        <section className="grid grid-cols-1 gap-8 mt-8">
          <div className="bg-primary rounded-lg p-6">
            <h3 className="text-3xl font-bold mb-4">Price Calculator</h3>
            <input
              type="range"
              min={250000000}
              max={20000000000}
              step={50000000}
              value={coinsToBuy}
              onChange={handleSliderChange}
              className="w-full"
            />
            <div className="flex justify-between text-lg mt-4">
              <span>250 million</span>
              <span>20 billion</span>
            </div>
            <div className="mt-4 relative">
              <div className="relative">
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleCoinsInputChange}
                  className="w-full bg-secondary py-3 px-4 rounded-lg text-lg text-center"
                />
                <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-lg">{inputValue.endsWith('b') ? 'b' : 'm'}</span>
              </div>
              <p className="text-xl mt-4">Selected Amount: {coinsToBuy.toLocaleString()}</p>
              <p className="text-xl">Price: ${calculatePrice().toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
            </div>
          </div>

          {hasUserCookie && (
            <>
              <div className="bg-primary rounded-lg p-6">
                <h3 className="text-3xl font-bold mb-4">In-Game Name (IGN)</h3>
                <input
                  type="text"
                  placeholder="Enter your IGN"
                  value={ign}
                  onChange={handleIGNChange}
                  className="w-full bg-secondary py-3 px-4 rounded-lg text-lg"
                />
              </div>

              <div className="bg-primary rounded-lg p-6">
                <h3 className="text-3xl font-bold mb-4">Payment Method</h3>
                <select
                  value={paymentMethod}
                  onChange={handlePaymentMethodChange}
                  className="w-full bg-secondary py-3 px-4 rounded-lg

 text-lg"
                >
                  <option value="" disabled>Select a payment method</option>
                  <option value="paypal">Paypal</option>
                  <option value="bitcoin">Crypto</option>
                  <option value="applecash">AppleCash</option>
                  <option value="cashapp">CashApp</option>
                  <option value="creditcard">Credit/Debit Card</option>
                  <option value="amazongc">Amazon Giftcards</option>
                </select>
              </div>

              <div className="flex justify-center">
                <button
                  className="bg-accent hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center transform hover:scale-105 transition-all shadow-lg"
                  onClick={handleSubmit}
                >
                  Buy Coins
                </button>
              </div>
            </>
          )}

          {isReporting && <p className="text-blue-500">Submitting...</p>}
          {reportStatus && (
            <p className={`${reportStatus.includes('Error') ? 'text-red-500 text-center' : 'text-green-500 text-center'}`}>
              {reportStatus}
            </p>
          )}
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default BuyPage;