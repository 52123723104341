import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faBitcoin, faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faCopy, faCreditCard, faMoneyBillWave, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const AccountsPage = () => {
  const [accounts, setAccounts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await fetch('https://api.wildismcoins.net/account');
        const data = await response.json();
        if (response.status === 200) {
          setAccounts(data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchAccounts();
  }, []);

  const copyLinkToClipboard = (accountNumber) => {
    const link = `https://wildismcoins.net/accounts/${accountNumber}`;
    navigator.clipboard.writeText(link);
  };

  const getPaymentIcon = (method) => {
    switch (method.toLowerCase()) {
      case 'paypal':
        return faPaypal;
      case 'crypto':
        return faBitcoin;
      case 'credit card':
        return faCreditCard;
      case 'bank transfer':
        return faUniversity;
      case 'cash app':
        return faMoneyBillWave;
      case 'apple cash':
        return faApple;
      default:
        return faCreditCard;
    }
  };

  const handleCardClick = (accountNumber) => {
    navigate(`/accounts/${accountNumber}`);
  };

  return (
    <div className="flex flex-col min-h-screen bg-background text-text">
      <Helmet>
        <title>Hypixel Skyblock Accounts for Sale</title>
        <meta
          name="description"
          content="Browse through our secure accounts available for sale from our trusted sellers. Find detailed stats and secure payment methods for each account."
        />
        <meta property="og:title" content="Hypixel Skyblock Accounts for Sale" />
        <meta
          property="og:description"
          content="Explore our wide selection of Skyblock accounts for sale from trusted sellers. Secure payment methods and detailed stats available for each account."
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
      </Helmet>
      <Header />

      <main className="container mx-auto px-8 py-12 flex-grow">
        <section className="text-center">
          <h2 className="text-5xl font-bold mb-8">Accounts for Sale</h2>
          <p className="text-xl mb-10">Browse through our secure accounts available for sale from our trusted sellers</p>
        </section>

        <section className="mt-10">
          <div className="flex flex-wrap -mx-4">
            {accounts.map((account, index) => (
              <div
                key={index}
                className="w-full md:w-1/2 px-4 mb-8 cursor-pointer"
                onClick={() => handleCardClick(account.accountNumber)}
              >
                <div className="bg-primary rounded-lg p-6 shadow-lg flex flex-col justify-between h-full">
                  <div className="mb-4">
                    <span className="font-bold">Rank:</span>
                    <div>{formatRank(account.stats.rank)}</div>
                  </div>
                  <div className="mb-4 grid grid-cols-3 gap-4">
                    <div>
                      <span className="font-bold">Level:</span>
                      <div>{account.stats.sblevel.toFixed(2)}</div>
                    </div>
                    <div>
                      <span className="font-bold">Skill Average:</span>
                      <div>{account.stats.skillaverage.toFixed(2)}</div>
                    </div>
                    <div>
                      <span className="font-bold">Slayer:</span>
                      <div>{`${account.stats.slayers.zombie}/${account.stats.slayers.spider}/${account.stats.slayers.wolf}/${account.stats.slayers.enderman}`}</div>
                    </div>
                  </div>
                  <div className="mb-4 grid grid-cols-3 gap-4">
                    <div>
                      <span className="font-bold">Networth:</span>
                      <div>Total: {account.stats.networth.total}</div>
                      <div>Unsoulbound: {account.stats.networth.unsoulbound}</div>
                      <div>Coins: {account.stats.networth.coins}</div>
                    </div>
                    <div>
                      <span className="font-bold">Weight:</span>
                      <div>Senither: {account.stats.weight.senither}</div>
                      <div>Lily: {account.stats.weight.lily}</div>
                    </div>
                    <div>
                      <span className="font-bold">Dungeons:</span>
                      <div>Catacombs: {account.stats.dungeons.level}</div>
                      <div>Class Average: {account.stats.dungeons.average.toFixed(2)}</div>
                    </div>
                  </div>
                  <div className="mb-4 grid grid-cols-2 gap-4">
                    <div>
                      <span className="font-bold">Mining:</span>
                      <div>HOTM Level: {account.stats.mining.hotm}</div>
                      <div>Mithril Powder: {account.stats.mining.mithril}</div>
                      <div>Gemstone Powder: {account.stats.mining.gemstone}</div>
                    </div>
                    <div>
                      <span className="font-bold">Minions:</span>
                      <div>Total Slots: {account.stats.minions.total_slots}</div>
                      <div>Until Next Slot: {account.stats.minions.crafted_slots}</div>
                      <div>Bonus Slots: {account.stats.minions.bonus_slots}</div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <span className="font-bold">Extra Stats:</span>
                    <div>{account.extraStats}</div>
                  </div>
                  <div className="mb-4">
                    <span className="font-bold">Price:</span>
                    <div>${account.price}</div>
                  </div>
                  <div className="mb-4">
                    <span className="font-bold">Payment Methods:</span>
                    <div className="flex flex-wrap gap-2">
                      {account.paymentMethods.map((method, methodIndex) => (
                        <span key={methodIndex} className="bg-secondary text-text py-1 px-2 rounded flex items-center">
                          <FontAwesomeIcon icon={getPaymentIcon(method)} className="mr-2" />
                          {method}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="flex justify-center mt-6 space-x-4">
                    <button
                      className="bg-accent hover:bg-accent-dark text-white py-2 px-6 rounded-full text-lg transform transition-transform duration-200 hover:scale-105"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCardClick(account.accountNumber);
                      }}
                    >
                      Buy Account
                    </button>
                    <button
                      className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-6 rounded-full text-lg flex items-center transform transition-transform duration-200 hover:scale-105"
                      onClick={(e) => {
                        e.stopPropagation();
                        copyLinkToClipboard(account.accountNumber);
                      }}
                    >
                      <FontAwesomeIcon icon={faCopy} className="mr-2" />
                      Copy Link
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

const formatRank = (rank) => {
  switch (rank) {
    case '[NON]':
      return (
        <span style={{ color: '#AAAAAA' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          NON
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    case '[VIP]':
      return (
        <span style={{ color: '#55FF55' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          VIP
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    case '[VIP+]':
      return (
        <span style={{ color: '#55FF55' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          VIP
          <span style={{ color: '#FFFFFF' }}>+</span>
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    case '[MVP]':
      return (
        <span style={{ color: '#55FFFF' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          MVP
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    case '[MVP+]':
      return (
        <span style={{ color: '#55FFFF' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          MVP
          <span style={{ color: '#FFFFFF' }}>+</span>
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    case '[MVP++]':
      return (
        <span style={{ color: '#FFAA00' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          MVP
          <span style={{ color: '#FFFFFF' }}>+</span>
          <span style={{ color: '#FFFFFF' }}>+</span>
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    case '[YOUTUBE]':
      return (
        <span style={{ color: '#FF5555' }}>
          <span style={{ color: '#FFFFFF' }}>[</span>
          YOUTUBE
          <span style={{ color: '#FFFFFF' }}>]</span>
        </span>
      );
    default:
      return <span style={{ color: '#AAAAAA' }}>{rank}</span>;
  }
};

export default AccountsPage;
