import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import ScammerList from './ScammerList';
import Middleman from './Middleman';
import Buy from './Buy';
import Sell from './Sell';
import NotFoundPage from './404';
import SuccessPage from './Success';
import AccountsPage from './Accounts';
import AccountDetailPage from './AccountsDetails';
import AccountBoosting from './AccountBoosting';
import FAQ from './FAQ';
import TOS from './TOS';
import TwoFAPage from "./MiddlemanTFA";
import BuyAccountPolicy from "./BuyAccountPolicy";
import SellAccountPolicy from "./SellAccountPolicy";
import CoinPolicy from "./CoinPolicy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/scammerlist" element={<ScammerList />} />
        <Route path="/middleman" element={<Middleman />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/accounts" element={<AccountsPage />} />
        <Route path="/accounts/:accountNumber" element={<AccountDetailPage />} />
        <Route path="/boosting" element={<AccountBoosting />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/tos" element={<TOS />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/middleman/2fa/:fasecret" element={<TwoFAPage />} />
        <Route path="/middleman/2fa" element={<TwoFAPage />} />
        <Route path="/policies/accounts/buy" element={<BuyAccountPolicy />} />
        <Route path="/policies/accounts/sell" element={<SellAccountPolicy/>} />
        <Route path="/policies/coins" element={<CoinPolicy />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
