import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';

const TOS = () => {
  return (
    <div className="flex flex-col min-h-screen bg-background text-text">
      <Header />
      <main className="container mx-auto px-8 py-12 flex-grow">
        <section className="text-center">
          <h2 className="text-5xl font-bold mb-8">Terms of Service</h2>
          <div className="text-left bg-primary p-6 rounded-lg space-y-6">
            <div>
              <h3 className="text-2xl font-semibold mb-2">Respect for All Users</h3>
              <p>You are required to exhibit respect towards all Wildism Coins users, regardless of your personal opinions towards them. You must treat others with respect and kindness and refrain from any form of bullying, harassment, or discrimination.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-2">Prohibition of Threats</h3>
              <p>Direct or indirect threats, including those of DDoS, death, abuse, or any other malicious actions, are strictly forbidden on Wildism Coins. This encompasses both jokes and false threats.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-2">Prohibited Content</h3>
              <p>We strictly disallow posting or distribution of any pornographic, adult, or other NSFW material on Wildism Coins. Gore or any other extremely violent or disturbing content is also expressly prohibited.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-2">Unsolicited Messages</h3>
              <p>You may not send unsolicited messages to other users without their explicit consent. Unsolicited messages include but are not limited to promotional content, marketing material, or any form of communication that has not been expressly requested by the recipient.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-2">Malicious Software</h3>
              <p>You must not engage in any activity related to the sale or distribution of malware, viruses, spyware, or any malicious software that can compromise the functionality of users' devices and data.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-2">Skyblock Services</h3>
              <p>
                <strong>Coin Sales:</strong> You are held responsible for anything that happens after your purchase. We carefully select our methods to ensure the lowest chance of incident but in the case that something happens we take no liability.<br />
                <strong>Account Sales:</strong> You must acknowledge that buying and selling Skyblock accounts may violate the game's terms of service, and you assume all risks associated with these transactions.<br />
                <strong>Boosting Service:</strong> By utilizing our boosting service, which involves logging into your account, you agree that Wildism Coins is not liable for any bans or penalties incurred while we are logged into your account. The risk of account bans is fully assumed by the account owner. Needless to say, we will not do anything that may cause a ban (e.g., macroing, cheating, boosting).
              </p>
            </div>
            <div>
              <p>Violation of these terms and conditions of use may result in the revocation of your access to Wildism Coins and any services/products associated with it, including but not limited to the server itself, our discord server, any virtual items, or accounts that are associated with your violation. We also reserve the right to change these terms and conditions without prior notice. Your use of Wildism Coins services constitutes your agreement to be bound by any such modifications.</p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default TOS;